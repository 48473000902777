import ReactGA from 'react-ga4';

import { getPageTitle } from './page';

function pageview(state) {
	const payload = {
		hitType: 'pageview',
		page: state.location.pathname,
		title: getPageTitle(),
	};

	if (process.env.NODE_ENV === 'production') {
		ReactGA.send(payload);
	} else {
		console.log('Analytics pageview would be sent with payload:', payload);
	}
}

function sendEvent(payload) {
	const { action, category, label } = payload;
	const event = {
		category: category || getPageTitle(),
		action,
		label,
	};

	if (process.env.NODE_ENV === 'production') {
		ReactGA.event(event);
	} else {
		console.log('Analytics event would be sent with payload:', event);
	}
}

export { pageview, sendEvent };
